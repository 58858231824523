.sign {
    background-color: white;
    color: grey; 
    width: 100%; height: 20px;
    font-size: 10pt;
    text-align: center;
    margin-bottom: 2px;

}

.sign-desc {
    height: 100%;
    font-weight: bold;

}

.sign-desc a {
    text-decoration: none;
    color: #d82020; 
}