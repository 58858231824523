.contact {
    display: flex; flex-direction: column; gap: 40px;
  
    padding-bottom: 50px;
    margin-bottom: 80px;
    width: 100%;

    overflow-x: hidden;
}

.cont-box {
    display: flex; flex-direction: row; 
    width: 100%; margin: auto;
    box-shadow: 0 0 15px 5px darkgreen;
}

.box11 {
    background-color: black;
    color: white; padding: 5%; flex: 1;
}

.box22 {
    background-color: goldenrod;
    color: black; padding: 5%; flex: 1; font-weight: bold;}

    .cont-last {
        display: flex; flex-direction: row; justify-content: space-around; align-items: center; margin-top: 100px; margin-bottom: 30px;
    }



.map-frame {
    width: 100%; 
    border-block: goldenrod 10px solid;
}



    .cont-org {
        text-decoration: none;
        text-align: center;
        color: darkgreen;
        transition: all 0.3s ease-out;

    }


    .cont-org:hover {
        color: goldenrod; transform: scale(1.05);
    }
    .cont-icon {
        transform: scale(3);
        margin-bottom: 16px;
    }



    @media (max-width: 678px) {

        .cont-box {
            flex-direction: column;
        }

        .cont-last {
            flex-direction: column; gap: 120px; 
        }
    }
    