.services {
    display: flex; flex-direction: column; gap: 40px;
    border-bottom: 50px solid darkgreen;
    padding-bottom: 50px;
    margin-bottom: 150px;
}

.serv-start {
    margin-inline: 10%;
    font-size: large;
    margin-bottom: 80px;
}

.icons {
    transform: scale(1.5); color: goldenrod;
}
.serv-start li {
    display: flex; flex-direction: row; gap: 30px; 
    justify-content: flex-start; align-items: center;
    margin-bottom: 20px;
}

.serv-desc {
    margin-inline: 10%; text-align: justify;
    display: flex; flex-direction: column; gap: 10px;
}

.serv-desc b {
    color: goldenrod;
    text-shadow: silver 0 0 1px
}

.serv-desc h2 {
    color: darkgreen; border-bottom: solid 5px goldenrod; padding-bottom: 5px; max-width: 90%; text-align: start;
}

.serv-desc i {
    text-decoration: none;
}

.serv-img {
    height: 300px; width: 50%; object-fit: cover; border-radius: 30px;
    margin-bottom: 80px; margin-inline: auto;
    box-shadow: 0 0 8px 3px silver;
}



@media (max-width: 678px) {

    .serv-img {
        width: 100%;
    }
}


@media (max-width: 325px) {
    .serv-start {
        margin-inline: 8px;
    }
}