.header {
    width: 100%;
    height: 100vh;
    position: relative;
    box-shadow:  0px 0px 36px 8px black;
    overflow: hidden;

    margin-bottom: 150px;

}

.head-img {
    position: absolute;
    top: 0; left: 0; width: 100%; height: 100%;
    object-fit: cover;
    background-blend-mode: color-burn;
}

.head-nav {
    position: absolute; 
    top: 0; left: 0; width: 100%; height: 100px; 
    background: linear-gradient(rgba(0, 100, 0, 1), rgba(0, 100, 0, 0.5));
}

.head-logo {
    height: 80%;
    border-radius: 15px; display: flex; align-items: center; margin-top: 6px; margin-left: 6px; transform: scale(0.825);
    border: grey 3px solid;
}

.head-list {
    position: absolute;
    right: 0; top: 0; height: 100%;
    display: flex; flex-direction: row; gap: 64px; place-items: center; margin-right: 64px;
 
}
.head-list a {
    text-decoration: none;    color: white; 
    transition: all 0.3s ease-out;
}

.head-list a:hover {
    text-decoration: underline;
    color: silver;
}

.head-h1 {
    position: absolute;
    top: 20%; right: 0%; color: white; background-color: darkgreen;
    padding: 8px 120px;
    font-size: 40pt;
     border-bottom-left-radius: 20px; border-top-left-radius: 20px;
     border: white 5px solid; border-right: none;
     opacity: 0.8;
     transition: all 0.3s ease-out;

}

.head-p {
    position: absolute;
    top: 50%; right: 0;
    color: black; background-color: goldenrod;
    padding: 8px 120px;
    font-size: 20pt;
     border-bottom-left-radius: 20px; border-top-left-radius: 20px;
     border: white 5px solid; border-right: none;
     opacity: 0.9;

     transition: all 0.3s ease-out;
     margin-left: 10%;
     text-align: justify;

} 

.head-h1:hover, .head-p:hover {
    transform: scaleX(1.05); opacity: 1;
}




@media (max-width: 1000px) {


.head-p {
    font-size: 16pt;     padding: 8px 60px;

}
.head-h1 {
    font-size: 32pt;  padding: 8px 60px;
}

}


@media (max-width: 725px) {
  
        .head-p {
            padding: 8px 30px;
            font-size: 15pt;
            max-width: 88%;
    }

    .head-h1 {
        padding: 8px 30px;
        font-size: 28pt;
    }

    .head-logo {
        width: 30%; aspect-ratio: 2/1;
    }

    .head-list {
        gap:32px;
        margin-right: 32px;
    }
}


@media (max-width: 550px) {
 

    .head-p {
        padding: 8px 16px;
            font-size: 13pt;
            width: 80%;
    }

    .head-h1 {
        padding: 8px 16px;
        font-size: 24pt;
    }
}





@media (max-width: 420px) {
    .head-logo {
        width: 30%; aspect-ratio: 2/1;
    }

.head-list {
    gap: 20px;
    margin-right: 20px;
}
}





@media (max-width: 320px) {
    .head-p {
        padding: 8px 8px;
            font-size: 10pt;
            width: 80%;
    }

    .head-h1 {
        padding: 8px 8px;
        font-size: 20pt;
    }

    .head-list {
        flex-direction: column;
    }
    .head-logo {
        width: 45%; aspect-ratio: 2/1;
    }

    .head-nav {
        height: 120px; place-content: center;
        font-size: 11pt;
    }
} 


