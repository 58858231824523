.about {
    display: flex; flex-direction: column; gap: 40px;
    border-bottom: 50px solid darkgreen;
    padding-bottom: 50px;
    margin-bottom: 150px;
}

.main-label {
    color: darkgreen; position: relative; width: max-content;
    margin-inline: auto;
    margin-bottom: 60px; max-width: 90%; text-align: center;

}

.main-label::before {
    content: "";
    position: absolute; top: -10px; left: 0; width: 100%; height: 5px;
    background-color: goldenrod; opacity: 1;
    transition: all 0.3s ease-out;

}

.main-label::after {
    content: "";
    position: absolute; bottom: -10px; left: 0; width: 100%; height: 5px;
    background-color: goldenrod; opacity: 1;
    transition: all 0.3s ease-out;

}

.main-label:hover.main-label::after,
.main-label:hover.main-label::before {
    opacity: 0;
}

.about-box {
    display: grid; grid-template-areas: 'box1 box2' 'box4 box3' 'box5 box6' 'box8 box7';
    width: 80%;
    margin: auto;
}

.about-box p {
    padding: 10%; text-align: justify;
}

.box1 { grid-area: box1;}
.box2 { grid-area: box2;}
.box3 { grid-area: box3;}
.box4 { grid-area: box4;}
.box5 { grid-area: box5;}
.box6 { grid-area: box6;}
.box7 { grid-area: box7;}
.box8 { grid-area: box8;}


.about-img {
    width: 100%; height: 250px; object-fit: cover; border: 5px solid goldenrod; border-radius: 50px;
}




@media (max-width: 875px) {
    .about-box {
        display: grid; grid-template-areas: 'box1' 'box2' 'box3' 'box4' 'box5' 'box6' 'box7' 'box8';
        width: 90%;
        margin: auto;
    }
}

@media (max-width: 678px) {
    .main-label {
        font-size: 20pt;
    }
}

@media (max-width: 330px) {
    .main-label {
        font-size: 18pt;
    }
}

