.footer {
    background-color: darkgreen;
    padding: 10% 10% 30px 10%;
    display: grid; grid-template-areas: 'one two three' 'four four four';
    place-items: center;
}

.foot-img {
    width: 50%;
    border-radius: 10px;
    border: goldenrod solid 5px
}

.one {grid-area: one;  aspect-ratio: 3/2;}
.two {grid-area: two;}
.three {grid-area: three;}
.four {grid-area: four;     margin-top: 50px;      line-height: 1;

}




.four p {
    color: white;
    font-size: 11pt;
    text-align: center;
}



@media (max-width: 678px) {

    .footer {
        padding: 10% 10% 30px 10%;
       
    }
    .foot-img {
       width: 80%;
        border: goldenrod solid 3px
    }
}
